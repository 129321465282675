export default {
  delayTitle: '0.5s',
  durationTitle: '0.5s',
  delaySubTitle: '0.8s',
  durationSubTitle: '0.3s',
  delayJob: '0.8s',
  durationJob: '0.7s',
  delaySubJob: '0.9s',
  durationSubJob: '0.6s',
  delayLine1: '0.8s',
  durationLine1: '0.4s',
  delayLine2: '1.1s',
  durationLine2: '0.4s',
  langDelay:'1.5s',
  langDuration: '0.5s'
};