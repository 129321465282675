export default [
  {
    name: 'maltIcon',
    url: 'https://www.malt.fr/profile/renauddechaux',
    title: 'Malt'
  },
  {
    name: 'githubIcon',
    url:'https://github.com/rdhox',
    title: 'Github'
  },
  {
    name: 'mailIcon',
    url: 'mailto:contact@rdhox.io',
    title: "mail"
  }
];